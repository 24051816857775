
import logo from "./logo.svg";
//import "../App.css";
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import dellLogo1 from "../assets/images/Dell Technologies_logo.png";
import dellLogo2 from "../assets/images/Dell AR Assistant_logo.png";
import logo1 from "../assets/images/Latitude3520_1.png";
import logo2 from "../assets/images/Remove_Off.png";
import logo3 from "../assets/images/Remove_On.png";
import logo4 from "../assets/images/Install_Off.png";
import logo5 from "../assets/images/Install_On.png";
import logo6 from "../assets/images/LOCATE_ON.png";
import logo7 from "../assets/images/SCREWS_ON.png";
import logo8 from "../assets/images/PART_ON.png";
import logo9 from "../assets/images/AR_Icon.png";
import logo10 from "../assets/images/CABLES_ON.png";
import logo11 from "../assets/images/LATCH_ON.png";
import logo12 from "../assets/images/VIDEO_ON.png";
import VideoPlayer from "react-video-js-player";
import ReactPlayer from "react-player";
import { GridLoader } from "react-spinners";
import {Buffer} from 'buffer';
import Device from 'react-device'
import {db} from '../utils/config'
import {collection,getDocs,setDoc,doc} from "firebase/firestore"
import moment from 'moment';
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { v4 as uuidv4 } from 'uuid';
import uuid from 'react-uuid';
//var crypto = require('crypto');
const encode = require('nodejs-base64-encode');
window.Buffer = window.Buffer || require("buffer").Buffer;

const url= 'https://dellarassistantapi.kaalo.com'


//import * as data from 'https://video-content-bucket-us-east-2-prod.s3.us-east-2.amazonaws.com/VideoTool_DellARAssistant.json';
const config = {
  attributes: {
    disablePictureInPicture: true,
    controlsList: "nodownload",
  },
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav_selected: "home",
      isClicked: false,
      selectedItem: 0,
      selectedSequence: 0,
      sequenceId: 1,
      isRemove: true,
      currentIndex: 0,
      isLoading: true,
      isPlaying: false,
      isMute: false,
      videoUri: "",
      video: {
        src: "http://www.example.com/path/to/video.mp4",
        poster: "http://www.example.com/path/to/video_poster.jpg",
      },
      modelName: "",
      stepName: "",
      stepListarray: [],
      sequnenceList: [],
      data: [],
      fulldata: [],
      VideoSource: "",
      CategoryName: "",
      isInvalid:false,
      isVisible:false,
      dropdownData:[],
      isDropdownLoading:true,
      dropdownName:'',
      accessToken:'',
      uuid:'',
      isFirstTimeLoad:false,
      timer:0,
      path_array1:[],
      uniqueId:''
    };
  }
  componentWillUnmount = async() => {
    const analytics = getAnalytics();
       logEvent(analytics, 'page_end',{time_in_sec:this.state.timer,product_name: this.state.path_array1[0],
       product_language: this.state.path_array1[1]});
  }
  // timer() {
  //   this.setState({
  //     timer: this.state.timer + 1
  //   })
    
  // }
  componentDidMount = async() => {
    console.log('shdbs')
    const value = localStorage.getItem('systemUniqueId');
    // console.log('acvhagcd',value)
    if(value){
      this.storeAction();
      // console.log('acvhagcd1',value)
    }else{
      let deviceUUID = uuidv4();
      localStorage.setItem('systemUniqueId', deviceUUID);
      this.storeAction();
    }
    
  };
  storeAction = async (actionData) => {
    let value = localStorage.getItem('systemUniqueId');
    let timestamp1= new Date().toString();
    const dateString = moment(timestamp1).format('YYYY-MM-DD HH:mm:ss');
    // console.log('dateString',dateString)
    this.setState({sysUniqId:value,enterTime:dateString})
    this.initialFunction()
  };
  async initialFunction(accessToken) {
    let body = {
      "username": 'admin',
      "password": 'Vx6rRwYaNCyE6dcN',
    }
    
    
    
    fetch(`${url}/api/token`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        ////console.log('sdvhshdchsdchs,',await response.json())
        let fullData=await response.json()
        //console.log('sdvhshdchsdchs1,',fullData)
        this.setState({accessToken:fullData.accessToken})
       // //console.log('sdvhshdchsdchs2,',await response.json())
        this.getData(fullData.accessToken)
        ////console.log('sdvhshdchsdchs3,',await response.json())
       // let data = await response.json();
       
      })
      .then(async (data) => {
        // let data2 = await data.json();
        // //console.log("datazdgfhgk112:", data2);
      })
      .catch(e => {
        //console.log('sdvhshdchsdchs4,',e)
      });
  }
  async getData(accessToken) {
    ////console.log("datazdgfhgk134567:", window.location.pathname); // output 'testing'
    
    //console.log('pathname',accessToken)
    var path = window.location.pathname;
    //console.log('pathname',path)
    var path_array = path.split("/");
    // console.log('pathname',path_array)
    let pathname=encode.decode(path_array[1], 'base64')
    // console.log('pathname1',pathname)
    let path_array1 = pathname.split("_");
    
    if(!this.state.isFirstTimeLoad){
     
      let uniqueId=uuid()
      this.setState({isFirstTimeLoad:true,path_array1:path_array1,uniqueId:uniqueId})
      // console.log('pathname12',uniqueId)
      const analytics = getAnalytics();
     /// setUserProperties(analytics, { favorite_food: 'apples' });
       logEvent(analytics, 'page_open',{type:'DTC',product_name: path_array1[0],
       product_language: path_array1[1],uniqueId:uniqueId});
       
    }
    
    // logEvent(analytics, 'select_content', {
    //   content_type: 'image',
    //   content_id: 'P12453'
    // });
    // logEvent(analytics,'select_content',{product_language:path_array1[1]})
    // console.log('pathname1234',test)
    //analytics.logEvent('count');
    // logEvent(analytics, path_array1[0]);
    // logEvent(analytics, 'page_view_count');
    // logEvent(analytics, 'DTC');
    // logEvent(analytics, path_array1[0]);
     //languageEvent={'open':{'language':path_array1[1],'type':'DTC','product_name':path_array1[1]}}
     
    //console.log('pathname',path_array)
    // var path_array1 = "";
    // pathname.map((item, index) => {
    //   path_array1 = item.split("_");
    // });

    // const getData = async () => {
    //   const res = await axios.get('https://geolocation-db.com/json/')
    //   //console.log(res.data);
    //   setIP(res.data.IPv4)
    // }

    

    fetch(`${url}/api/VideoTool_DellARAssistant?timestamp=${path_array1[2]}&deviceId=${this.state.uuid}&tempURL=${path_array[1]}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "accesstoken":accessToken,
      },
    })
      .then(async (response) => {
        let Promise = await response.json();
        //console.log("datazdgfhgknew02:", response);
       // //console.log('sdvhshdchsdchs,1',await response.json())
        if(Promise.message){
          this.setState({isInvalid:true})
          //console.log("datazdgfhgknew02:", response);
        }else{
          //console.log("datazdgfhgknew01:", Promise);
        let filterValue = path_array1[0].replaceAll(/\s/g, "");
        let modelValue = "";
         //console.log('pathname1',path_array1)
        Promise.ProductList.map((item, index) => {
          
          //console.log("datazdgfhgknew030:", item);
          if(item.isSpecial){
            let filterValue = `${path_array1[0].replaceAll(/\s/g, "")}_${path_array1[1].replaceAll(/\s/g, "")}`;
            if (
              item.ModelName.replaceAll(/\s/g, "").toUpperCase() ==
              filterValue.toUpperCase()
            ) {
               //console.log('pathname21',item.ModelName.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
              modelValue = item.ModelName.replaceAll(/\s/g, "");
               //console.log('pathname3',path_array1)
              this.fetchData(modelValue, path_array1, item);
              //console.log("datazdgfhgknew03:", modelValue,path_array1);
            }
            //console.log("filterValuefilterValue:", filterValue);
          }else if(item.isSubCategoty){
            // console.log('pathname22',item.Product.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
            if (
              item.Product.replaceAll(/\s/g, "").toUpperCase() ==
              filterValue.toUpperCase()
            ) {
             //  console.log('pathname23',item.Product.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
              modelValue = item.SubCategotyData.subCategoryList[0].subCatModel;
              // console.log('pathname24',item.SubCategotyData.subCategoryList[0].subCatModel)
              this.setState({dropdownName:item.SubCategotyData.subCategoryHeaderName,path_array1:path_array1,item:item,isVisible:true,dropdownData:item.SubCategotyData.subCategoryList})
              this.fetchData(modelValue, path_array1, item);
              //console.log("datazdgfhgknew03:", modelValue,path_array1);
            }
          }else {
            //console.log('pathname2',item.ModelName.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
           ////console.log("datazdgfhgknew031:", item.ModelName.replaceAll(/\s/g,''),filterValue);
           if (
             item.ModelName.replaceAll(/\s/g, "").toUpperCase() ==
             filterValue.toUpperCase()
           ) {
              //console.log('pathname21',item.ModelName.replaceAll(/\s/g, "").toUpperCase(),filterValue.toUpperCase())
             modelValue = item.ModelName.replaceAll(/\s/g, "");
              //console.log('pathname3',path_array1)
             this.fetchData(modelValue, path_array1, item);
             //console.log("datazdgfhgknew03:", modelValue,path_array1);
           }
         } 
        });
        }
        
      })
      .then((err) => {
       //console.log("datazdgfhgk2:", err);
      });

  }

  fetchData(modelValue, path_array1, item) {
    //console.log("datazdgfhgknew02fghj:", this.state.accessToken);
    fetch(`${url}/api/arassistant?model_name=${modelValue}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "accesstoken":this.state.accessToken,
      },
    })
      .then(async (response) => {
        let Promise = await response.json();
        //console.log("datazdgfhgknew02:", Promise);
        let currentIndex = 0;
       // //console.log("datazdgfhgknew021:", path_array1[1]);
        if (path_array1[1]) {
          if (path_array1[1].toUpperCase() == "EN") {
            currentIndex = 0;
          } else if (path_array1[1].toUpperCase() == "ES") {
            currentIndex = 3;
          } else if (path_array1[1].toUpperCase() == "PT") {
            currentIndex = 6;
          } else if (path_array1[1].toUpperCase() == "JA") {
            currentIndex = 5;
          } else if (path_array1[1].toUpperCase() == "CCC") {
            currentIndex = 1;
          }
        }
       // //console.log("datazdgfhgknew022:",currentIndex);

        this.setState({
          languageCode:path_array1[1],
          stepName:
            Promise.languageList[currentIndex].procedureList[0].procedureName,
          fulldata: Promise.languageList,
          sequnenceList:
            Promise.languageList[currentIndex].procedureList[0].sequnenceList,
          modelName: item.Product,
          data: Promise.languageList[currentIndex],
          currentIndex: currentIndex,
          VideoSource: item.VideoSource,
          CategoryName: item.CategoryName,
          ProductImgName:
            "https://video-content-bucket-us-east-2-prod.s3.us-east-2.amazonaws.com/ProductImgage/" +
            item.ProductImgName +
            ".png",
        });
        this.onselectStepListInitial(
          Promise.languageList[currentIndex].procedureList[0].sequnenceList[0]
            .sequenceId,
          this.state.isRemove
        );
      })
      .then((err) => {
       // //console.log("datazdgfhgk2:", err);
      });
  }

  onselectStepListInitial = async (item, isRemove) => {
   // //console.log("datazdgfhgknew023:",item,this.state.data.sequenceList);
    if (this.state.data.sequenceList) {
     // //console.log("datazdgfhgknew024:");
      let activeCategories = this.state.data.sequenceList.filter(
        (cat) => cat.sequenceId == item
      );
     // //console.log("datazdgfhgknew025:",activeCategories);
      let fullActiveCategories = this.state.fulldata[0].sequenceList.filter(
        (cat) => cat.sequenceId == item
      );
    //  console.log("datazdgfhgknew026:",activeCategories[0]);
      let isValidurl = true;

      let timestamp1= new Date().toString();
      const dateString = moment(timestamp1).format('YYYY-MM-DD HH:mm:ss');
      let procetureTime=this.state.stepName+","+dateString
      let ModelValue=this.state.modelName+","+this.state.languageCode+","+'DTC'
      await setDoc(doc(db, this.state.sysUniqId, this.state.enterTime,ModelValue,procetureTime), {
        sequenceName:activeCategories[0].sequneceName,
        instalationType:this.state.isRemove?'Remove':'Install'
      });
    //   const analytics = getAnalytics();
    // logEvent(analytics, 'sequence',{uniqueId:this.state.uniqueId,procedure_name:this.state.stepName,product_name: this.state.path_array1[0],
    // product_language: this.state.path_array1[1],sequence_name:activeCategories[0].sequneceName,sequence_type:this.state.isRemove?'remove':'install'});
      
     // //console.log("datazdgfhgknew027:",activeCategories[0].isOptional_Sequence);
      if (activeCategories[0].isOptional_Sequence) {
     //   //console.log("datazdgfhgknew028:",activeCategories[0].optionSequenceIDList);
        if (activeCategories[0].optionSequenceIDList) {
      //    //console.log("datazdgfhgknew029:");
          activeCategories = this.state.data.sequenceList.filter(
            (cat) =>
              cat.sequenceId == activeCategories[0].optionSequenceIDList[0]
          );
       //   //console.log("datazdgfhgknew030:",activeCategories);
          fullActiveCategories = this.state.fulldata[0].sequenceList.filter(
            (cat) =>
              cat.sequenceId == fullActiveCategories[0].optionSequenceIDList[0]
          );
        //  //console.log("datazdgfhgknew031:",fullActiveCategories);
        } else {
         // //console.log("datazdgfhgknew032:",fullActiveCategories);
          isValidurl = false;
        }
      }
     // //console.log("datazdgfhgk11:", isRemove);
     // //console.log("datazdgfhgk111:", fullActiveCategories);
      if (isRemove) {
        let url =
          isValidurl && fullActiveCategories[0].removeVideoURl != ""
            ? fullActiveCategories[0].removeVideoURl
            : "dell";
      //  //console.log("dsvgcgsdhc0", url);
      //  //console.log("dsvgcgsdhc01", fullActiveCategories[0]);
        let videoUri =
          "https://d3u57nouj7te3v.cloudfront.net/" +
          this.state.VideoSource +
          "/" +
          url +
          ".mp4";
        if (this.player) {
          this.player.seekTo(0);
        } else {
          // this.onPressProcedure(this.state.data.procedureList[0],0);
        }
       // //console.log("dsvgcgsdhc", activeCategories[0].removeStepList);
        let stepListarray = [];
        activeCategories[0].removeStepList.map((item, index) => {
          let value = item;
          value.stepCount = `${index + 1}/${
            activeCategories[0].removeStepList.length
          }`;
          
          if (item.isNestedStep) {
            item.nestedStepList.map((data, indx) => {
              let value = data;
              value.stepCount = `${index+1} ${indx + 1}/${item.nestedStepList.length}`;
              stepListarray.push(data);
            });
          }else{
            stepListarray.push(item);
          }
        });
        this.setState({
          isLoading: false,
          isDropdownLoading:false,
          videoUri: videoUri,
          stepListarray: stepListarray,
        });
        this.setState({ isPlaying: true, isMute: true });
        setTimeout(() => {
          this.setState({ isMute: true, isPlaying: true });
        }, 4000);
      } else {
        let url =
          isValidurl && fullActiveCategories[0].installVideoURl != ""
            ? fullActiveCategories[0].installVideoURl
            : "dell";

        let videoUri =
          "https://d3u57nouj7te3v.cloudfront.net/" +
          this.state.VideoSource +
          "/" +
          url +
          ".mp4";
        this.player.seekTo(0);
        let stepListarray = [];
        activeCategories[0].installStepList.map((item, index) => {
          let value = item;
          value.stepCount = `${index + 1}/${
            activeCategories[0].installStepList.length
          }`;
          if (item.isNestedStep) {
            item.nestedStepList.map((data, indx) => {
              let value = data;
              value.stepCount = `${index+1} ${indx + 1}/${item.nestedStepList.length}`;
              stepListarray.push(data);
            });
          }else{
            stepListarray.push(item);
          }
        });

        this.setState({
          isLoading: false,
          isDropdownLoading:false,
          videoUri: videoUri,
          stepListarray: stepListarray,
        });
      }
    }else{
      this.getData(this.state.accessToken)
    }
  };
  checkurlPath() {
    ////console.log('this.state.data',this.state.data.languageList[0].sequenceList)
    this.state.data.sequenceList.map((item, index) => {
      

     
      //this.checkurl}
    });
  }
  // checkurl = async (url) => {
  //   this.checkFIle(
  //     "https://d3u57nouj7te3v.cloudfront.net/Latitude/" + url + ".mp4"
  //   )
  //     .then(() => {
  //      // //console.log("this.state.data1", url, true);
  //       return true;
  //     })
  //     .catch((err) => {
  //      // //console.log("this.state.data1", url, false);
  //       return false;
  //     });
  // };
  checkFIle = async (url) => {
    let check = await fetch(url, { method: "HEAD" });
   // //console.log("this.state.data2", url, check);
    return fetch(url, { method: "HEAD" });
  };

  onPressProcedure = (item, index) => {
    // console.log('fullData====>', this.state.fulldata[0].procedureList[3].sequnenceList[0]    )
    this.setState({
      stepName: item.procedureName,
      selectedSequence: 0,
      selectedItem: index,
      isRemove: true,
      sequnenceList: item.sequnenceList,
      sequenceId: this.state.isRemove
        ? item.sequnenceList[0].sequenceId
        : item.sequnenceList[0].sequenceId,
    });
    // console.log('fullData1====>', this.state.fulldata[0].procedureList[3].sequnenceList[0]    )
    this.onselectStepList(
      item.sequnenceList[0].sequenceId,
      this.state.isRemove
    );
  };
  onPressSequence = (item, index) => {
    this.setState({ selectedSequence: index, sequenceId: item.sequenceId });
    this.onselectStepList(item.sequenceId, this.state.isRemove);
    // const analytics = getAnalytics();
    // logEvent(analytics, 'sequence',{uniqueId:this.state.uniqueId,procedure_name:this.state.stepName,product_name: this.state.path_array1[0],
    // product_language: this.state.path_array1[1],sequence_name:item.sequenecName,sequence_type:this.state.isRemove?'remove':'install'});
  };
  onSequenceChange = (val) => {
    // Reverse the sequence list safely
    const reversedSequence = [...this.state.sequnenceList].reverse();
  
    // Determine the updated sequenceId based on `val`
    const updatedSequenceId = val
      ? reversedSequence[0].sequenceId
      : reversedSequence[reversedSequence.length - 1].sequenceId;
  
    // State update logic based on the `val` and `isRemove`
    if (val && !this.state.isRemove) {
      // Update the reversed sequence and call onselectStepList
      this.setState(
        {
          sequnenceList: reversedSequence,
          selectedSequence: 0,
          isRemove: val,
          sequenceId: updatedSequenceId,
        },
        () => {
          // Perform operations after state update
          this.onselectStepList(this.state.sequnenceList[0].sequenceId, val);
        }
      );
    } else if (!val && this.state.isRemove) {
      // Update the reversed sequence and call onselectStepList
      this.setState(
        {
          sequnenceList: reversedSequence,
          selectedSequence: 0,
          isRemove: val,
          sequenceId: updatedSequenceId,
        },
        () => {
          // Perform operations after state update
          this.onselectStepList(this.state.sequnenceList[0].sequenceId, val);
        }
      );
    } else {
      // Only update sequenceId, selectedSequence, and isRemove if no reversal
      this.setState({
        selectedSequence: 0,
        isRemove: val,
        sequenceId: updatedSequenceId,
      });
    }
  
    // Debugging logs to check values at different stages
    console.log("Updated Sequence List:", reversedSequence);
    console.log("Full Data Reference:", this.state.fulldata[0].procedureList[3].sequnenceList[0]);
  };
  
  // onSequenceChange = (val) => {
   
  //   if (val) {
  //    if(!this.state.isRemove){
  //     let sequence = [...this.state.sequnenceList].reverse();
  //      console.log('sequencesequence',sequence)
  //      this.setState({sequnenceList:sequence})
  //    }
  //    } else {
  //      if(this.state.isRemove){
  //       let sequence = [...this.state.sequnenceList].reverse();
  //       this.setState({ sequnenceList: sequence });
  //       console.log('sequencesequence01',sequence)
  //        console.log('sequencesequence1',this.state.sequnenceList)
  //      }
  //      console.log('fullData23====>', this.state.fulldata[0].procedureList[3].sequnenceList[0]    )
   
  //    }
  //   if (val) {
  //   if(!this.state.isRemove){
  //     let sequence=this.state.sequnenceList
  //     this.setState({sequnenceList:sequence})
  //   }
  //     this.onselectStepList(this.state.sequnenceList[0].sequenceId, val);
  //   } else {
  //     if(this.state.isRemove){
  //       let sequence=this.state.sequnenceList
  //       this.setState({sequnenceList:sequence})
  //     }
  //     this.onselectStepList(
  //       this.state.sequnenceList[0]
  //         .sequenceId,
  //       val
  //     );
  //   }
  //   this.setState({
  //     selectedSequence: 0,
  //     isRemove: val,
  //     sequenceId: val
  //       ? this.state.sequnenceList[0].sequenceId
  //       : this.state.sequnenceList[this.state.sequnenceList.length - 1]
  //           .sequenceId,
  //   });
  //   console.log('sequencesequence2',this.state.sequnenceList)
  // };
  onselectStepList = async(item, isRemove) => {
    // console.log('fullData2====>', this.state.fulldata[0].procedureList[3].sequnenceList[0]    )
    console.log('sequencesequence21',this.state.sequnenceList)
    if (this.state.data.sequenceList) 
    {
      let activeCategories = this.state.data.sequenceList.filter(
        (cat) => cat.sequenceId == item
      );
      let fullActiveCategories = this.state.fulldata[0].sequenceList.filter(
        (cat) => cat.sequenceId == item
      );
      let isValidurl = true;

      setTimeout(async() => {
        let timestamp= new Date().toString();
      const dateString = moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
      let procetureTime=this.state.stepName+","+dateString
      let ModelValue=this.state.modelName+","+this.state.languageCode+","+'DTC'
      await setDoc(doc(db, this.state.sysUniqId, this.state.enterTime,ModelValue,procetureTime), {
        sequenceName:activeCategories[0].sequneceName,
        instalationType:this.state.isRemove?'Remove':'Install'
      });
        
      }, 2000)
    //    const analytics = getAnalytics();
    // logEvent(analytics, 'sequence',{uniqueId:this.state.uniqueId,procedure_name:this.state.stepName,product_name: this.state.path_array1[0],
    // product_language: this.state.path_array1[1],sequence_name: activeCategories[0].sequneceName,sequence_type:this.state.isRemove?'remove':'install'});
      // console.log("dfhvbjdf4:", activeCategories[0].sequneceName);
      if (activeCategories[0].isOptional_Sequence) {
        if (activeCategories[0].optionSequenceIDList) {
          activeCategories = this.state.data.sequenceList.filter(
            (cat) =>
              cat.sequenceId == activeCategories[0].optionSequenceIDList[0]
          );

          fullActiveCategories = this.state.fulldata[0].sequenceList.filter(
            (cat) =>
              cat.sequenceId == fullActiveCategories[0].optionSequenceIDList[0]
          );
        } else {
          isValidurl = false;
        }
      }
    //  //console.log("dfhvbjdf43:", activeCategories);
     // //console.log("dfhvbjdf44:", fullActiveCategories);
      if (isRemove) {
        let url =
          isValidurl && fullActiveCategories[0].removeVideoURl != ""
            ? fullActiveCategories[0].removeVideoURl
            : "dell";
      //  //console.log("dsvgcgsdhc0", url);
       // //console.log("dsvgcgsdhc01", fullActiveCategories[0]);
        let videoUri =
          "https://d3u57nouj7te3v.cloudfront.net/" +
          this.state.VideoSource +
          "/" +
          url +
          ".mp4";
        if (this.player) {
          this.player.seekTo(0);
        } else {
          // this.onPressProcedure(this.state.data.procedureList[0],0);
        }
      //  //console.log("dsvgcgsdhc", activeCategories[0].removeStepList);
        let stepListarray = [];
        activeCategories[0].removeStepList.map((item, index) => {
          let value = item;
          value.stepCount = `${index + 1}/${
            activeCategories[0].removeStepList.length
          }`;
          if (item.isNestedStep) {
            item.nestedStepList.map((data, indx) => {
              let value = data;
              value.stepCount = `${index+1} ${indx + 1}/${item.nestedStepList.length}`;
              stepListarray.push(data);
            });
          }else{
            stepListarray.push(item);
          }
        });
        this.setState({
          isLoading: false,
          isDropdownLoading:false,
          videoUri: videoUri,
          stepListarray: stepListarray,
        });
      } else {
        let url =
          isValidurl && fullActiveCategories[0].installVideoURl != ""
            ? fullActiveCategories[0].installVideoURl
            : "dell";

        let videoUri =
          "https://d3u57nouj7te3v.cloudfront.net/" +
          this.state.VideoSource +
          "/" +
          url +
          ".mp4";
        this.player.seekTo(0);
        let stepListarray = [];
        activeCategories[0].installStepList.map((item, index) => {
          let value = item;
          value.stepCount = `${index+1} ${index + 1}/${
            activeCategories[0].installStepList.length
          }`;
          if (item.isNestedStep) {
            item.nestedStepList.map((data, indx) => {
              let value = data;
              value.stepCount = `${indx + 1}/${item.nestedStepList.length}`;
              stepListarray.push(data);
            });
          }else{
            stepListarray.push(item);
          }
        });

        this.setState({
          isLoading: false,
          isDropdownLoading:false,
          videoUri: videoUri,
          stepListarray: stepListarray,
        });
      }
    }
    console.log('sequencesequence22',this.state.sequnenceList)
    // console.log('fullData3====>', this.state.fulldata[0].procedureList[3].sequnenceList[0]    )
  };
  onVideoChange = () => {
    if (this.state.sequnenceList.length != this.state.selectedSequence + 1) {
      
      this.onselectStepList(
        this.state.sequnenceList[this.state.selectedSequence + 1].sequenceId,
        this.state.isRemove
      );
      this.setState({
        selectedSequence: this.state.selectedSequence + 1,
        sequenceId: this.state.sequnenceList[this.state.selectedSequence + 1],
      });
      //this.onPressProcedure(this.state.sequnenceList[this.state.selectedSequence+1],this.state.selectedSequence+1)
      // this.setState({selectedSequence:this.state.selectedSequence+1,videoUri:'https://d3u57nouj7te3v.cloudfront.net/Latitude/Latitude_3520_Battery_Cable_R_02.mp4'})
    }
  };
  onChange = (deviceInfo) => {
    let id=deviceInfo?.browser?.major+
    deviceInfo?.browser?.name+
    deviceInfo?.browser?.version+
    deviceInfo?.cpu?.architecture+
    deviceInfo?.device?.model+
    deviceInfo?.device?.type+
    deviceInfo?.device?.vendor+
    deviceInfo?.engine?.name+
    deviceInfo?.engine?.version+
    deviceInfo?.os?.name+
    deviceInfo?.os?.version+
    deviceInfo?.screen?.defaultFontSize+
    deviceInfo?.screen?.height+
    deviceInfo?.screen?.orientation+
    deviceInfo?.screen?.width+
    deviceInfo?.screen?.widthEm+
    deviceInfo?.ua
    //console.log('Screen height', id)
    let id1=id.replace(/\s/g, "")
    let id2=id1.replace(/\\|\//g,'')
    this.setState({uuid:id2})
  }
  render() {
    console.log('sequencesequence3',this.state.sequnenceList)
    return (
      <div className="container body-content ">
        <Device onChange={(e) => {this.onChange(e)}} />
        {this.state.isInvalid ? (
          <div className="grid-design">
            Invalid url, please try with some valid url
          </div>
        ) :
        this.state.isLoading ? (
          <div className="grid-design">
            <GridLoader
              sizeUnit={"px"}
              size={10}
              margin={"2px"}
              color={"#5a7ced"}
              loading={this.state.isLoading}
            />
          </div>
        ) : (
          <React.Fragment>
            <div className="form-group row m-0 logo-section">
              <div className="col-sm-3">
                <img src={dellLogo1} />
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-8">
                <img src={dellLogo2} />
              </div>
           
              {/* <div className="col-sm-8">
                <select className="selectBoxPro">
                  <option value="volvo">Volvo</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                </select>
              </div> */}
              
            </div>
           

            
            <form method="post">
              <div className="form-horizontal">
                <div className="form-group m-0 img">
                  <div className="row">
                    <div className="col-sm-1 col-md-1 img-section">
                      <img src={this.state.ProductImgName} />
                    </div>
                    <div className="col-sm-6 col-md-6 text-section">
                      <div className="large">{this.state.modelName}</div>
                      <div className="small">
                        {this.state.CategoryName} series
                      </div>
                    </div>
                    {this.state.isVisible?
              <div className="col-sm-5 col-md-5 selectBoxHeader">
              <span className="text-black fs-18"> {this.state.dropdownName}</span>
                <select 
                onChange={(e) => {
                  let modelValue = e.target.value;
                 // //console.log('pathname24',item.SubCategotyData.subCategoryList[0].subCatModel)
                  this.setState({isDropdownLoading:true})
                  this.fetchData(modelValue, this.state.path_array1, this.state.item);
                    // let activeCategories = this.state.productList.filter(
                    //   (cat) => cat.CategoryName == e.target.value
                    // );
                    // this.setState({isContentLoading:true,modalList:activeCategories[0].products})
                    // let modelValue = activeCategories[0].products[0].ModelName.replaceAll(/\s/g, "");
                    // //console.log('dchjdjc2',modelValue)
                    // this.fetchData(modelValue,activeCategories[0].products[0],this.state.isChecked)
                    //this.onSequenceChange(true, item, index);
                  }} className="selectBoxPro">
                {this.state.dropdownData.map((item, index) => (
                  
                  <option value={item.subCatModel}>{item.subCatName}</option>
                 
                ))}
                </select>
            </div> :null}
                  </div>
                </div>
                <div className="row">
                  <br />
                </div>
                <div></div>
                <React.Fragment>
                {this.state.isDropdownLoading ? (
          <div className="grid-design">
            <GridLoader
              sizeUnit={"px"}
              size={10}
              margin={"2px"}
              color={"#5a7ced"}
              loading={this.state.isDropdownLoading}
            />
          </div>
        ) :
                <div className="form-group m-0 left-section row">
                  <div className="col-md-3 bor-1">
                    <div>
                      <div className="text-left">
                        <div className="txt">
                          Part replacement <br /> videos
                        </div>
                      </div>
                      <div className="sml-txt">
                        Select a part to preview the part replacement procedure
                      </div>
                      <div>
                        {this.state.data.procedureList.map((item, index) => (
                          <div key={index} className="menu-parent active">
                            <div
                              onClick={() => {
                                this.onPressProcedure(item, index);
                                const analytics = getAnalytics();
                                //analytics.logEvent('count');
                               // const analytics = getAnalytics();
       logEvent(analytics, 'Procedure',{uniqueId:this.state.uniqueId,procedure_name:item.procedureName,product_name: this.state.path_array1[0],
       product_language: this.state.path_array1[1]});
                                //logEvent(analytics, item.procedureName);
                              }}
                              className={
                                this.state.selectedItem == index
                                  ? "menu-title active"
                                  : "menu-title"
                              }
                            >
                              {item.procedureName}
                            </div>
                            {index == this.state.selectedItem ? (
                              <div id="pro_0" className="menu">
                                <div
                                  onClick={() => {
                                    this.onSequenceChange(true);
                                  }}
                                  id="remove_0"
                                  className={
                                    this.state.isRemove
                                      ? "submenu-title active"
                                      : "submenu-title"
                                  }
                                >
                                  Remove
                                </div>
                                <div
                                  onClick={() => {
                                    this.onSequenceChange(false);
                                  }}
                                  id="install_0"
                                  className={
                                    !this.state.isRemove
                                      ? "submenu-title active"
                                      : "submenu-title"
                                  }
                                >
                                  Install
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="video-menu col-sm-12 col-md-9">
                    <div className="row col-sm-12 col-md-12 p-15">
                      <div className="col-sm-12 col-md-9 text-center p-15">
                        <div id="videopanel" className="">
                          
                          <ReactPlayer
                            ref={(player) => (this.player = player)}
                            url={this.state.videoUri}
                            playing={this.state.isPlaying}
                            controls={true}
                            width={585}
                            volume={10}
                            height={328}
                            config={config}
                            muted={this.state.isMute}
                            onEnded={() => {
                              this.onVideoChange();
                            }}
                          />
                          {/* <video id="videoplayer" width="585" height="328" autopictureinpicture="true" controls="" autoplay="autoplay" muted="">
                                            <source src="https://d3u57nouj7te3v.cloudfront.net/Latitude/Latitude_3520_Battery_3cell_R_Full_Length.mp4" type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video> */}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 p-15">
                        <div id="seqdata" className="seqdata">
                          {this.state.isRemove ? (
                            <div>
                              {this.state.sequnenceList.map((item, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    this.onPressSequence(item, index);
                                  }}
                                  id="seqmenu_0"
                                  className={
                                    this.state.selectedSequence == index
                                      ? "submenu-text active"
                                      : "submenu-text"
                                  }
                                >
                                  <div className="color-3">
                                    {" "}
                                    {index + 1}/
                                    {this.state.sequnenceList.length}
                                  </div>
                                  <div className="seq-name">
                                    {" "}
                                    {item.sequenecName}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              {this.state.sequnenceList.map((item, index) => (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      this.onPressSequence(item, index);
                                    }}
                                    id="seqmenu_0"
                                    className={
                                      this.state.selectedSequence == index
                                        ? "submenu-text active"
                                        : "submenu-text"
                                    }
                                  >
                                    <div className="color-3">
                                      {" "}
                                      {index + 1}/
                                      {this.state.sequnenceList.length}
                                    </div>
                                    <div className="seq-name">
                                      {" "}
                                      {item.sequenecName}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {/* <script type="text/javascript">
                                            $(document).ready(function () {
                                                 
                                                $('#seqmenu_0').trigger("click");
                                            
                                            });
                                        </script> */}
                        </div>
                      </div>
                    </div>
                    <div className="row col-md-12 c-section p-15">
                      <div className="col-sm-12 col-md-9">
                        <div id="pro_name">{this.state.stepName}</div>
                      </div>
                      <div className="col-sm-12 col-md-3">
                        <div className="row">
                          <div
                            onClick={() => {
                              this.onSequenceChange(true);
                            }}
                            className={
                              this.state.isRemove
                                ? "pull-left col-md-6 tab active"
                                : "pull-left col-md-6 tab"
                            }
                          >
                            {this.state.isRemove ? (
                              <img src={logo3} className="img-remove-on" />
                            ) : (
                              <img
                                src={logo2}
                                className="img-remove-off hide"
                              />
                            )}
                            <div className="remove"> Remove</div>
                          </div>
                          <div
                            onClick={() => {
                              this.onSequenceChange(false);
                            }}
                            className={
                              this.state.isRemove
                                ? "pull-right col-md-6 text-right tab"
                                : "pull-right col-md-6 text-right tab active"
                            }
                          >
                            <div className="install"> Install </div>
                            {this.state.isRemove ? (
                              <img src={logo4} className="img-install-off" />
                            ) : (
                              <img
                                src={logo5}
                                className="img-install-on hide"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="stepdata">
                      <div>
                        <div className="col-sm-12 col-md-12 p-15">
                          <table>
                            <tbody>
                              {this.state.stepListarray.map((item, index) => (
                                <tr
                                  key={index}
                                  id="step_play_0"
                                  className="bor-grey"
                                >
                                  <td className="tleft">
                                    {item.stepName == "Lock" ? (
                                      <img src={logo6} className="step-icon" />
                                    ) : item.stepName == "Screw" ? (
                                      <img src={logo7} className="step-icon" />
                                    ) : item.stepName == "Part" ? (
                                      <img src={logo8} className="step-icon" />
                                    ) : item.stepName == "Tape" ? (
                                      <img src={logo9} className="step-icon" />
                                    ) : item.stepName == "Cable" ? (
                                      <img src={logo10} className="step-icon" />
                                    ) : item.stepName == "Latch" ? (
                                      <img src={logo11} className="step-icon" />
                                    ) : (
                                      <img src={logo12} className="step-icon" />
                                    )}
                                  </td>
                                  <td className="tcenter">
                                    <div className="tstep">
                                      Step {item.stepCount}
                                    </div>
                                    <b> {item.stepTxt}</b>
                                  </td>
                                  <td className="tright">
                                    <b> {item.stepInforTxt}</b>
                                  </td>
                                </tr>
                              ))}

                              {/* <tr id="step_play_1" className="bor-grey">
                                                    <td className="tleft">
                                                        <img src={logo7} className="step-icon"/>
                                                    </td>
                                                    <td className="tcenter" >
                                                        <div className="tstep">
                                                            Step 2/3
                                                        </div>
                                                        <b> Remove screws</b>
                                                    </td>
                                                    <td className="tright">
                                                        <b> Remove the screws that secure the battery to the system.</b>
                                                    </td>
                                                </tr>
                                                <tr id="step_play_2" className="bor-grey">
                                                    <td className="tleft">
                                                        <img src={logo8} className="step-icon"/>
                                                    </td>
                                                    <td className="tcenter" >
                                                        <div className="tstep">
                                                            Step 3/3
                                                        </div>
                                                        <b> Remove battery</b>
                                                    </td>
                                                    <td className="tright">
                                                        <b> Lift to remove the battery from the system.</b>
                                                    </td>
                                                </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
                </React.Fragment>
              </div>
            </form>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default App;
